import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

const useProgressiveImage = src => {
  const [sourceLoaded, setSourceLoaded] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  }, [src])

  return sourceLoaded
}

export default props => {
  const loaded = useProgressiveImage(
    props.node.frontmatter.thumbnail &&
      props.node.frontmatter.thumbnail.childImageSharp.fluid.src
  )

  return (
    // ${props.count % 3 === 0 ? `post-card-large` : ''}
    <article
      className={`post-card ${props.postClass} ${
        props.node.frontmatter.thumbnail ? `with-image` : `no-image`
      }`}
      style={
        loaded && {
          backgroundImage: `url(${loaded})`,
        }
      }
    >
      <Link to={props.node.fields.slug} className="post-card-link">
        <div className="post-card-content">
          <h2
            className="post-card-title"
            style={{ textTransform: "uppercase" }}
          >
            {props.node.frontmatter.title || props.node.fields.slug}
          </h2>
        </div>
      </Link>
    </article>
  )
}
