import React, { useEffect } from "react"
import styles from "./index.module.css"

const YoutubePlayer = props => {
  useEffect(() => {
    // YouTube Player API for header BG video

    // Insert the <script> tag targeting the iframe API
    const tag = document.createElement("script")
    tag.src = "https://www.youtube.com/iframe_api"
    const firstScriptTag = document.getElementsByTagName("script")[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    // Get the video ID passed to the data-video attribute
    const bgVideoID = "ZyEONHkImmw"

    // Set the player options
    const playerOptions = {
      // Autoplay + mute has to be activated (value = 1) if you want to autoplay it everywhere
      // Chrome/Safari/Mobile
      autoplay: 1,
      mute: 1,
      autohide: 1,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
      controls: 0,
      disablekb: 1,
      enablejsapi: 1,
      iv_load_policy: 3,
      // For looping video you have to have loop to 1
      // And playlist value equal to your currently playing video
      loop: 1,
      playlist: bgVideoID,
      origin: window.location,
    }

    // Get the video overlay, to mask it when the video is loaded
    const videoOverlay = document.querySelector(".js-video-overlay")

    // This function creates an <iframe> (and YouTube player)
    // after the API code downloads.
    let ytPlayer = null

    // The API will call this function when the video player is ready.
    function onPlayerReady(event) {
      event.target.playVideo()
      const videoDuration = event.target.getDuration()
      setInterval(function() {
        const videoCurrentTime = event.target.getCurrentTime()
        const timeDifference = videoDuration - videoCurrentTime

        if (2 > timeDifference > 0) {
          event.target.seekTo(0)
        }
      }, 1000)
    }

    function onPlayerStateChange(event) {
      if (event.data == window.YT.PlayerState.PLAYING) {
        videoOverlay.classList.add("header__video-overlay--fadeOut")
      }
    }

    window.onYouTubeIframeAPIReady = () => {
      console.log("---------------Video Ready---------------")
      if (ytPlayer) {
        return
      }
      ytPlayer = new window.YT.Player("yt-player", {
        width: "1280",
        height: "720",
        videoId: bgVideoID,
        playerVars: playerOptions,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      })
    }
  })

  return (
    <div className={styles.header}>
      <div className="header__video js-background-video">
        <div className="header__background">
          <div id="yt-player"></div>
        </div>
      </div>
      <div
        className="header__video-overlay js-video-overlay"
        style={{
          backgroundImage:
            "url('https://img.youtube.com/vi/69yA-F7yOiQ/maxresdefault.jpg')",
        }}
      ></div>
      {/* <h1 className="header__title">Hell Yeah!</h1> */}

      {/* <iframe width="1587" height="677" src="https://www.youtube.com/embed/PzQTJKPsGIU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe> */}
    </div>
  )
}

export default YoutubePlayer
