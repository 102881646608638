import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"
import YoutubePlayer from "../components/ui/youtubePlayer/youtubePlayer.js"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const allPosts = data.allMarkdownRemark.edges
  let postCounter = 0
  const postCategories = {
    photography: "photography",
    videography: "videography",
    portfolio: "portfolio",
  }

  // let videoPortfolio = [
  //   '<iframe width="560" height="315" src="https://www.youtube.com/embed/ZyEONHkImmw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>',
  // ]

  // let photographyPosts = () => {
  //   return posts.filter(
  //     el => el.node.fields.slug.slice(1, 12) === postCategories.photography
  //   )
  // }

  // let videographyPosts = () => {
  //   return posts.filter(
  //     el => el.node.fields.slug.slice(1, 12) === postCategories.videography
  //   )
  // }

  let PortfolioPosts = () => {
    // console.log("posts")
    const posts = allPosts
    // console.log(posts)
    return posts
  }

  return (
    <Layout title={siteTitle}>
      <SEO title="Home" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      {/* <Bio /> */}
      {/* {data.site.siteMetadata.description && (
        <header className="page-head">
          <h3 className="heading-level-3">
            {data.site.siteMetadata.description}
          </h3>
        </header>
      )} */}

      <YoutubePlayer />

      {/* <p style={{ textAlign: "center", marginTop: "24px" }}>
        <b>
          Dubai Videographer | Event Photographer | Wedding Photographer | Dubai
          Photographer | Abu Dhabi Photographer | Event Videographer | Corporate
          Videographer
        </b>
      </p> */}

      {/* Portfolio */}
      <h3
        className="heading-level-3"
        style={{ textAlign: "center", textTransform: "uppercase" }}
      >
        Portfolio
      </h3>
      <div className="post-feed">
        {PortfolioPosts().map(node => {
          postCounter++
          return (
            <PostCard
              key={node.node.fields.slug}
              count={postCounter}
              node={node.node}
              postClass={`post`}
            />
          )
        })}
      </div>

      {/* Photography */}
      <h3
        className="heading-level-3"
        style={{ textAlign: "center", textTransform: "uppercase" }}
      >
        Videography
      </h3>
      <div className="post-feed">
        <article className={`post-card`}>
          <iframe
            title="Peter England - Gentleman Race and Classic Porsches Collector"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/ZyEONHkImmw"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </article>

        <article className={`post-card`}>
          <iframe
            title="Nafas Retreat 2020 Feel Edit of the event"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/o47SdYvAq80"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </article>

        <article className={`post-card no-image`}>
          <iframe
            title="Gerry O'Leary - Architectural Photographer - BTS"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/8Bbmohqi84U"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </article>

        <article className={`post-card no-image`}>
          <iframe
            title="Six Construct - Mr. Pereira Farewell Video"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/dtFvEKMjo-A"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </article>

        <article className={`post-card no-image`}>
          <iframe
            title="Optimum Nutrition SNS - Workshop Sessions - Dubai"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/M45LuvxD2Yc"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </article>

        <article className={`post-card no-image`}>
          <iframe
            title="Emaar Sales App - Launch Video"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/9ncNA7a8Tww"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </article>

        <article className={`post-card no-image`}>
          <iframe
            title="Gulfmeteor - Company Profile"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/yDBVFUpsmi8"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </article>

        <article className={`post-card no-image`}>
          <iframe
            title="Annick Goutal - Mother's day Event - Short version"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/uN5xFhysTr0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </article>
      </div>

      {/* Videos */}
      {/* <h3 className="heading-level-3" style={{ textAlign: "center" }}>
        Videography
      </h3>
      <div className="post-feed">
        {videographyPosts().map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div> */}

      {/* Photography */}
      {/* <h3 className="heading-level-3" style={{ textAlign: "center" }}>
        Photography
      </h3>
      <div className="post-feed">
        {photographyPosts().map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div> */}
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
